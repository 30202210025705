
.data-center {
    position: fixed;
    top: 60px;
    left: 210px;
    right: 0;
    bottom: 0;
    background: #F6F7FC;

    ::v-deep > .el-scrollbar__wrap {
        overflow-x: hidden;

        .el-scrollbar__view {
            height: calc(100% - 40px);
            margin: 20px;
        }
    }
}

.data-content {
    display: flex;
    flex-direction: column;
    height: 100%;

    .top-title {
        height: 50px;
        width: 100%;
        display: flex;
        justify-content: center;
        position: relative;

        .text {
            position: absolute;
            line-height: 50px;
            text-align: center;
            font-size: 20px;
            color: #fff;
        }
    }
}

.filter-box {
    display: flex;
    justify-content: space-between;

    .filter-right {

        .right-text {
            font-size: 17px;
            font-weight: bold;
            color: #666666;
            margin-right: 15px;
        }

        i {
            font-size: 21px;
            color: #2DC079;
            cursor: pointer;
        }
    }
}

.middle-content {
    margin-top: 15px;
    display: flex;

    .left-box {
        width: 25%;
    }

    .middle-box {
        width: 50%;
        height: 100%;
        margin: 0 30px;

        .data-card {
            height: 100%;
            display: flex;
            flex-direction: column;

            .top-exam {
                width: 100%;
                flex: 1;
                height: 1%;
            }
        }
    }
}

.bottom-content {
    margin-top: 30px;
    padding-bottom: 30px;
    display: flex;

    .bottom-left {
        width: 33%;
    }

    .bottom-card {
        flex: 1;

        &:nth-of-type(2) {
            margin: 0 30px;
        }
    }

    .bottom-right {
        width: 35%;

        .table-box {
            height: 340px;
            padding: 20px 0;
            margin: 0 30px;
        }
    }
}

.data-card {
    background: #FFFFFF;
    box-shadow: 0px 4px 14px 0px rgba(27, 73, 52, 0.1);
    border-radius: 6px;

    .filter-box {
        padding: 20px 0;
        margin: 0 30px;
        border-bottom: 1px solid #EEEEEE;
        align-items: center;

        .el-select {
            width: 150px
        }

        .el-select + .el-select {
            margin-left: 22px;
        }

        .filter-title {
            font-weight: bold;
            color: #696969;
        }
    }
}

.icon-card {
    width: 100%;
    display: flex;
    justify-content: space-between;

    &:last-child {
        margin-top: 30px;
    }

    .card-item {
        width: 50%;
        padding: 0 20px;
        margin: 20px 0;

        &:first-child {
            border-right: 1px solid #EEEEEE;
        }

        .item.title {
            font-weight: bold;
            color: #696969;
        }

        .card-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 22px;

            .icon-box {
                width: 65px;
                height: 65px;
                background: #EEEEEE;
                box-shadow: 0px 3px 4px 0px rgba(27, 73, 52, 0.15);
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .bottom-data {
                display: flex;
                margin: 20px 0 0 20px;
                align-items: flex-end;

                .data-num {
                    font-size: 56px;
                    font-weight: bold;

                    &.blue {
                        color: #438CF5;
                    }

                    &.green {
                        color: #40C482;
                    }

                    &.yellow {
                        color: #FE9E1E;
                    }

                    &.pink {
                        color: #F97780;
                    }
                }

                .grey-num {
                    font-size: 30px;
                    color: #767676;
                    line-height: 45px;
                }

                .data-unit {
                    margin-left: 11px;
                    font-size: 16px;
                    font-weight: bold;
                    color: #767676;
                    margin-bottom: 10px;
                }
            }
        }
    }
}
